import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Turnstile, { useTurnstile } from 'react-turnstile';
import * as Sentry from '@sentry/astro';
import { create, enforce, test } from 'vest';
import LoadingSpinner from './LoadingSpinner.tsx';

const SignInForm = ({ isProd }) => {
	const [formData, setFormData] = useState({
		username: '',
		token: '',
		password: '',
	});
	const [errors, setErrors] = useState([]);
	const [formErrors, setFormErrors] = useState({});
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const turnstile = useTurnstile();

	const suite = create((data = {}) => {
		test('username', 'Username is required', () => {
			enforce(data.username).isNotBlank();
		});

		test('password', 'Password is required', () => {
			enforce(data.password).isNotBlank();
		});
	});

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const setToken = (tokenValue: string) => {
		setFormData((prevState) => ({
			...prevState,
			token: tokenValue,
		}));
	};

	const submitForm = (e: Event) => {
		e.preventDefault();
		const validationResult = suite(formData);

		if (validationResult.isValid()) {
			setLoading(true);
			const jsonData = JSON.stringify(formData);
			const requestOptions = {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: jsonData,
			};
			fetch(`${import.meta.env.PUBLIC_API_URL}/login`, requestOptions)
				.then(async (result) => {
					if (!result.ok && isProd) {
						turnstile.reset();
					}
					const res = await result.json();
					if (res.hasOwnProperty('errors')) {
						setErrors(res.errors);
					} else {
						setSuccess(true);
						setErrors([]);
						Cookies.set('token', res.token, { expires: 1 });
						window.location.href = '/';
					}
				})
				.catch((e) => {
					if (e.hasOwnProperty('errors')) {
						setErrors(e.errors);
					}
					Sentry.captureException(e);
					if (isProd) {
						turnstile.reset();
					}
				})
				.finally(() => setLoading(false));
		} else {
			setFormErrors(validationResult.getErrors());
		}
	};

	useEffect(() => {
		setFormErrors({});
	}, [formData]);

	return (
		<div>
			{errors.length > 0 && (
				<div
					className="mb-3 bg-red-100 border border-red-200 text-sm text-red-800 rounded-lg p-4 dark:bg-red-800/10 dark:border-red-900 dark:text-red-500"
					role="alert"
				>
					{errors[0]}
				</div>
			)}
			{!success && (
				<form onSubmit={submitForm}>
					<div className="grid gap-y-4">
						<div>
							<label htmlFor="username" className="block text-sm mb-2 dark:text-white">
								Username
							</label>
							<div className="relative">
								<input
									type="username"
									id="username"
									value={formData.username}
									onChange={handleChange}
									name="username"
									placeholder="Username"
									className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
									required
									aria-describedby="username-error"
								/>
								<div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
									<svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
										<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
									</svg>
								</div>
								{formErrors.hasOwnProperty('username') && (
									<p className="text-sm text-red-600 mt-2" id="hs-validation-name-error-helper">
										{formErrors['username'][0]}
									</p>
								)}
							</div>
							<p className="hidden text-xs text-red-600 mt-2" id="email-error">
								Please include a valid username so we can get back to you
							</p>
						</div>
						<div>
							<div className="flex justify-between items-center">
								<label htmlFor="password" className="block text-sm mb-2 dark:text-white">
									Password
								</label>
								<a
									className="text-sm text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
									href="/restore"
								>
									Forgot password?
								</a>
							</div>
							<div className="relative">
								<input
									type="password"
									id="password"
									placeholder="Password"
									value={formData.password}
									onChange={handleChange}
									name="password"
									className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
									required
									aria-describedby="password-error"
								/>
								<div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
									<svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
										<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
									</svg>
								</div>
								{formErrors.hasOwnProperty('password') && (
									<p className="text-sm text-red-600 mt-2" id="hs-validation-name-error-helper">
										{formErrors['password'][0]}
									</p>
								)}
							</div>
							<p className="hidden text-xs text-red-600 mt-2" id="password-error">
								8+ characters required
							</p>
						</div>
						<div className="flex items-center">
							<div className="flex">
								<input
									id="remember-me"
									name="remember-me"
									type="checkbox"
									className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
								/>
							</div>
							<div className="ms-3">
								<label htmlFor="remember-me" className="text-sm dark:text-white">
									Remember me
								</label>
							</div>
						</div>
						{isProd && (
							<Turnstile
								sitekey="0x4AAAAAAAQ0N2bQQc_CmQuw"
								onVerify={(token) => {
									setToken(token);
								}}
							/>
						)}
						<button
							onClick={submitForm}
							disabled={(isProd && formData.token?.length === 0) || loading}
							type="submit"
							className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
						>
							<LoadingSpinner active={loading} />
							Sign in
						</button>
					</div>
				</form>
			)}
		</div>
	);
};

export default SignInForm;
